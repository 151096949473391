import {
  checkInWithNoPasswordStaticQRCode,
  checkInWithPersonalQRCode,
  getEmail,
  validateMerits,
} from "../components/Checkin-Web/constant/api";
// eslint-disable-next-line no-unused-vars
import { MeritUserInfo } from "../types/user";
import { Some } from "../utils/Some";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import history from "../history";
import jwtDecode from "jwt-decode";
import { showErrorToast } from "../utils/ToastHelper";
import { useRedirectLogin } from "./auth";

const showFailedCheckedInToast = () => {
  const logInWith = localStorage.getItem("logInWith")?.toLowerCase() ?? "checkin";

  showErrorToast({
    message: `Failed to ${logInWith} with the provided QR code`,
  });
};

const handleSuccessfulCheckin = async (checkInResponse: QRScanData, qrData: string) => {
  const {
    attendeeDetails,
    isMemberAlreadyCheckedIn,
    metQualification,
    nameOfDependentEvent,
    policyRunResponse: policyRunDetails,
    sigmaMemberId: checkinUserId,
  } = checkInResponse;

  datadogRum.setUser({ checkinUserId });
  datadogLogs.setUser({ checkinUserId });

  if (isMemberAlreadyCheckedIn) {
    history.push({ pathname: "/web-checkin/already-checkedin", state: { attendeeDetails } });
  } else if (metQualification) {
    history.push({ pathname: "/web-checkin/checkin-success", state: { attendeeDetails } });
  } else if (Some(nameOfDependentEvent)) {
    history.push({
      pathname: "/web-checkin/checkin-failure-dependent",
      state: { attendeeDetails, missingCheckIn: nameOfDependentEvent },
    });
  } else if (Some(policyRunDetails) && policyRunDetails.length > 0) {
    const templateIDs = policyRunDetails.reduce((templateIDs: string[], { policy }) => {
      const requiredTemplateIds = policy.rules.own.reduce((ids: string[], own) => {
        return own.arguments.length > 1 ? [...ids, ...own.arguments.slice(1)] : ids;
      }, []);

      return [...templateIDs, ...requiredTemplateIds];
    }, []);

    const urlQuery = `?isKiosk=1`;

    const response = await validateMerits(urlQuery, { memberIdToken: qrData, templateIDs }, true);
    const item = response.data.item;

    history.push({
      pathname: "/web-checkin/checkin-failure",
      state: {
        attendeeDetails: attendeeDetails,
        policyRunDetails,
        validateMeritsResponse: item,
      },
    });
  } else {
    history.push({
      pathname: "/web-checkin/checkin-failure",
      state: {
        attendeeDetails: attendeeDetails,
        policyRunDetails,
      },
    });
  }
};

const useNoPasswordCheckin = () => {
  const handleCheckinForStaticQRCode = useStaticQRScanCheckin();

  const handleCheckIn = async (eventId: number, dependentEventId: number, qrData: string) => {
    const locationData = localStorage.getItem("locationData");

    const {
      data: { accessToken, errorType, item: checkInResponse, success: isCheckinSuccessful },
    } = await checkInWithNoPasswordStaticQRCode({
      dependentEventId,
      entityID: qrData,
      eventId,
      location: locationData ? JSON.parse(locationData) : null,
    });

    if (errorType === "PasswordRequired") {
      await handleCheckinForStaticQRCode(qrData);
    } else if (errorType === "EntityNotFoundError") {
      const { attendeeDetails, nameOfDependentEvent } = checkInResponse;
      history.push({
        pathname: "/web-checkin/checkin-failure-dependent",
        state: { attendeeDetails, missingCheckIn: nameOfDependentEvent },
      });
    } else if (errorType === "QueryFailedError") {
      showErrorToast({
        message: "Checkin failed due to multiple users trying to checkin concurrently. Please try again.",
      });
    } else if (Some(accessToken)) {
      const { userEntityID: checkinUserId } = jwtDecode<MeritUserInfo>(accessToken);
      datadogRum.setUser({ checkinUserId });
      datadogLogs.setUser({ checkinUserId });

      history.push({
        pathname: "/web-checkin/qr-scan/tos",
        state: { accessToken, checkinType: "NO_PASSWORD_QR_CODE_SCAN", qrData },
      });
    } else if (isCheckinSuccessful) {
      await handleSuccessfulCheckin(checkInResponse, qrData);
    } else if (errorType === "QueryFailedError") {
      showErrorToast({
        message: "Checkin failed due to multiple users trying to checkin concurrently. Please try again.",
      });
    } else {
      showFailedCheckedInToast();
    }
  };

  const handleCheckOut = async (qrData: string) => {
    // Checkout using no password scan is not implemented yet
    await handleCheckinForStaticQRCode(qrData);
  };

  const handleNoPasswordQRCodeScan = async (eventId: number, dependentEventId: number, qrData: string) => {
    const logInWith = localStorage.getItem("logInWith");

    if (logInWith === "checkIn") {
      await handleCheckIn(eventId, dependentEventId, qrData);
    } else if (logInWith === "checkOut") {
      await handleCheckOut(qrData);
    }
  };

  return handleNoPasswordQRCodeScan;
};

const usePersonalQRScanCheckin = () => {
  const handleCheckIn = async (eventId: number, qrData: string) => {
    const locationData = localStorage.getItem("locationData");

    const response = await checkInWithPersonalQRCode(qrData, eventId, locationData ? JSON.parse(locationData) : null);

    const {
      data: { accessToken },
    } = response;

    if (Some(accessToken)) {
      const { userEntityID: checkinUserId }: MeritUserInfo = jwtDecode<MeritUserInfo>(accessToken);
      datadogRum.setUser({ checkinUserId });
      datadogLogs.setUser({ checkinUserId });

      history.push({
        pathname: "/web-checkin/qr-scan/tos",
        state: { accessToken, checkinType: "PERSONAL_QR_CODE_SCAN", qrData },
      });
    } else if (response.data.success) {
      await handleSuccessfulCheckin(response.data.item, qrData);
    } else {
      showFailedCheckedInToast();
    }
  };

  const handleCheckOut = async (qrData: string) => {
    history.push({ pathname: "/web-checkin/validate-member-login", state: { personalQRData: qrData } });
  };

  const handlePersonalQRCodeScan = async (eventId: number, qrData: string) => {
    const logInWith = localStorage.getItem("logInWith");

    if (logInWith === "checkIn") {
      await handleCheckIn(eventId, qrData);
    } else if (logInWith === "checkOut") {
      await handleCheckOut(qrData);
    }
  };

  return handlePersonalQRCodeScan;
};

const useStaticQRScanCheckin = () => {
  const promptLogin = useRedirectLogin();

  const handleStaticQRCodeScan = async (translationUUID: string) => {
    try {
      const {
        data: { email },
      } = await getEmail(translationUUID);

      await promptLogin(email);
    } catch (error) {
      showFailedCheckedInToast();
    }
  };

  return handleStaticQRCodeScan;
};

export { useNoPasswordCheckin, usePersonalQRScanCheckin, useStaticQRScanCheckin };
