/* eslint-disable no-irregular-whitespace*/
import { Button, Checkbox, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { checkOut, checkOutWithPersonalQRCode, getCheckOutEvents } from "./constant/api";
// eslint-disable-next-line no-unused-vars
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Header } from "./Layout/Header";
import LoadingScreen from "../UI-Components/LoadingScreen";
import { Some } from "../../utils/Some";
import history from "../../history";
import { showErrorToast } from "../../utils/ToastHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";

const styles = StyleSheet.create({
  activityTitle: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "30px",
  },
  buttonWrapper: {
    alignItems: "center",
    background: Colors.white,
    display: "flex",
    height: "100px",
    justifyContent: "center",
    width: "100%",
  },
  checkboxWrapper: {
    alignItems: "center",
    background: "white",
    boxShadow: `0px 1px 0px ${Colors.checkOutShadow}, 0px -1px 0px ${Colors.checkOutShadow}`,
    display: "flex",
    height: "112px",
    padding: "26px",
    width: "327px",
  },
  checkedBackground: {
    background: Colors.solitude,
    boxShadow: `0px 1px 0px ${Colors.checkOutShadow}, 0px -1px 0px ${Colors.checkOutShadow}`,
  },
  checkinText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "10px",
    textAlign: "left",
  },
  checkoutButton: {
    background: Colors.defaultButtonBg,
    borderRadius: "4px",
    color: Colors.white,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    textTransform: "uppercase",
    width: "327px",
  },
  checkoutName: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "10px",
    textAlign: "left",
  },
  checkoutText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
    marginTop: "5px",
    textAlign: "left",
  },
  eventTitle: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "30px",
    wordBreak: "break-all",
  },
  footerWrapper: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    position: "sticky",
  },
  headerContainer: {
    paddingTop: "30px",
    textAlign: "left",
    width: "327px",
  },
  mainContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
});

interface LocationState {
  memberToken: string;
  personalQRData: string;
}
interface Props extends RouteComponentProps<{}, {}, LocationState> {
  readonly notify: (message: string, status: string) => void;
}

const CheckOutEvents: React.FC<Props> = ({ notify, location }) => {
  const {
    state: { personalQRData },
  } = location;
  const isPersonalQRScanCheckin = Some(personalQRData);

  const { Content, Footer } = Layout;
  const { push, goBack } = history;
  const { isAuthenticated, logout } = useAuth0();
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<readonly CheckInEvent[]>([]);
  const [eventAttendeeIds, setEventAttendeeIds] = useState<Array<number>>([]);
  const { errorHandler } = useDefaultErrorHandler();

  useEffect(() => {
    if (isAuthenticated || isPersonalQRScanCheckin) {
      getCheckOut();
    }
  }, [isAuthenticated]);

  const getCheckOut = async () => {
    try {
      if (location.state !== undefined) {
        const { memberToken } = location.state;
        const response = isPersonalQRScanCheckin
          ? await checkOutWithPersonalQRCode(personalQRData)
          : await getCheckOutEvents(memberToken);
        if (response.data.item.checkedinEvents.length > 0) {
          const events = response.data.item.checkedinEvents;
          setEvents(events);
          const eventIdList = events.map((event: CheckedOutEvents) => event.eventAttendeeId);

          if (response.data.item.checkedinEvents.length === 1) {
            await handleCheckOut(eventIdList);
          }

          setEventAttendeeIds(eventIdList);
        } else {
          push("/web-checkin/nothingtocheckout");
        }
      }
    } catch (e: any) {
      showErrorToast({
        message: e.message,
        onClose: () => {
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/web-checkin/home`,
            },
          });
        },
      });
    }
    setLoading(false);
  };

  const handleChange = (event: CheckboxChangeEvent) => {
    const eventId = event.target.value;
    let selectedEventIds: number[];

    if (eventAttendeeIds.includes(eventId)) {
      selectedEventIds = eventAttendeeIds.filter((event: number) => event !== eventId);
    } else {
      selectedEventIds = eventAttendeeIds.concat(eventId);
    }

    setEventAttendeeIds(selectedEventIds);
  };

  const handleCheckOut = async (attendeeIds: number[]) => {
    if (attendeeIds.length > 0) {
      try {
        const locationData = localStorage.getItem("locationData");
        const geolocation = locationData ? JSON.parse(locationData) : null;
        const params = {
          eventAttendeeId: attendeeIds,
          location: geolocation,
        };
        const response: any = await checkOut(params, isPersonalQRScanCheckin);
        const getRequiredDetails = response.data.map((checkOutDetails: CheckOutResponse) => {
          const { checkoutTime } = checkOutDetails.data;
          const { checkIn, event, firstName, lastName } = checkOutDetails.data.item;
          const { name, locationEnabled, timezone } = event;
          const location = checkOutDetails.location;

          return { checkIn, checkoutTime, firstName, lastName, location, locationEnabled, name, timezone };
        });
        push({
          pathname: "/web-checkin/checkoutsuccess",
          state: { checkOutDetails: getRequiredDetails },
        });
      } catch (error: unknown) {
        errorHandler(error);
      }
    } else {
      notify("Please select atleast one activity", "error");
    }
  };

  const handleCheckboxView = (): React.ReactNode => {
    return events.map((event: CheckedOutEvents) => {
      return (
        <div
          className={
            eventAttendeeIds.includes(event.eventAttendeeId)
              ? css(styles.checkboxWrapper, styles.checkedBackground)
              : css(styles.checkboxWrapper)
          }
        >
          <Checkbox
            value={event.eventAttendeeId}
            onChange={handleChange}
            checked={eventAttendeeIds.includes(event.eventAttendeeId)}
            data-testid={`${event.eventTitle}-Checkbox-CheckOutEvents`}
          ></Checkbox>
          <div style={{ padding: "15px" }}>
            <span className={css(styles.eventTitle)} data-testid={`${event.eventTitle}-Activity-CheckOutEvents`}>
              {event.eventTitle}
            </span>
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <React.Fragment>
          <Header backArrow exitButton handleExit={() => push("/web-checkin/home")} handleGoBack={() => goBack()} />
          <Content className="checkout_events">
            <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
              <div className={css(styles.mainContainer)}>
                <div className={css(styles.headerContainer)}>
                  <p className={css(styles.activityTitle)}>Check-out</p>
                  <p className={css(styles.checkoutText)} data-testid="checkOutFromActivitiesMessage-CheckOutEvents">
                    Check-out from following activities
                  </p>
                </div>
                {handleCheckboxView()}
              </div>
            </Row>
          </Content>
          <div className={css(styles.footerWrapper)}>
            <Footer className={css(styles.buttonWrapper)}>
              <Button
                type="default"
                block
                className={css(styles.checkoutButton)}
                onClick={() => handleCheckOut(eventAttendeeIds)}
                data-testid="checkOutButton-CheckOutEvents"
              >
                check-out
              </Button>
            </Footer>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(CheckOutEvents);
