import { Button, Card, Layout, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import React from "react";
import { TermsOfServiceModal } from "../TermsOfServiceModal";
import history from "../../history";
import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureFlag } from "../../hooks/launchDarkly";
import { useLogin } from "../../hooks/auth";
import { useStore } from "../../store/store";

const styles = StyleSheet.create({
  accountText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    textAlign: "left",
  },
  activityText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "10px",
    textAlign: "left",
  },
  buttonWrapper: {
    background: Colors.defaultButtonBg,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "72px",
    letterSpacing: "1.25px",
    lineHeight: "20px",
    textAlign: "left",
    textTransform: "uppercase",
    width: "263px",
  },
  cardContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    width: "327px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginTop: "10px",
    textAlign: "left",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
  },
  flexWrapper: {
    textAlign: "left",
  },
  loginButtonMargin: {
    marginTop: "16px",
  },
  meritText: {
    color: Colors.darkBlue,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    marginTop: "15px",
    textAlign: "left",
  },
  mobileAppText: {
    fontSize: "12px",
    opacity: 0.5,
    paddingLeft: "38px",
  },
  scanButtonMargin: {
    marginTop: "10px",
  },
});

export const CheckOutWith = () => {
  const { isAuthenticated } = useAuth0();
  const intermediateScreen = useStore((state) => state.intermediateScreen);
  const setIntermediateScreen = useStore((state) => state.setIntermediateScreen);
  const { showWebQrScan } = useFeatureFlag();

  const promptLogin = useLogin();
  const { Content } = Layout;
  const { push, goBack } = history;
  localStorage.setItem("logInWith", "checkOut");

  const handleCheckedLogin = async () => {
    try {
      await promptLogin();
    } catch (err: unknown) {
      push("/web-checkin/home");
    }
  };

  if (isAuthenticated && intermediateScreen === "termsOfService") {
    return <TermsOfServiceModal />;
  }

  if (isAuthenticated && intermediateScreen === "selectOrg") {
    setIntermediateScreen(undefined);
    push("/web-checkin/validate-member-login");

    return null;
  }

  return (
    <React.Fragment>
      <Header backArrow exitButton handleExit={() => push("/web-checkin/home")} handleGoBack={() => goBack()} />
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <h2 className={css(styles.checkinText)} data-testid="actionMessage-CheckOutWith">
                How do you want to check-out ?
              </h2>
              <div className="checkout_with_card">
                <div className={css(styles.cardContainer)}>
                  <Card className={css(styles.cardWrapper)}>
                    <h3 className={css(styles.accountText)}>With my Merit account</h3>

                    {showWebQrScan && (
                      <Button
                        className={css([styles.buttonWrapper])}
                        type="primary"
                        icon="qrcode"
                        size="large"
                        onClick={() => {
                          push({ pathname: "/web-checkin/qr-scan", state: { actionType: "checkOut" } });
                        }}
                      >
                        scan qr code
                      </Button>
                    )}

                    <Button
                      className={css([styles.buttonWrapper, styles.loginButtonMargin])}
                      type="primary"
                      icon="mail"
                      size="large"
                      onClick={handleCheckedLogin}
                      data-testid="loginWithEmailPasswordButton-CheckOutWith"
                    >
                      login with email &amp;
                      <br /> <span style={{ paddingLeft: "38px" }}>password</span>
                    </Button>
                  </Card>
                </div>
              </div>
            </Row>
          </Row>
        </div>
      </Content>
      <Footer />
    </React.Fragment>
  );
};
