import "./webcheckin.less";
import { AboutMerit } from "./AboutMerit";
import AlreadyCheckIn from "./AlreadyCheckIn";
import Auth0RedirectCallback from "./Auth0RedirectCallback";
import CheckInAccessMerit from "./CheckInAccessMerit";
import CheckInCheckOutQrScan from "./CheckInCheckOutQrScan";
import CheckInFailure from "./CheckInFailure";
import CheckInFailureDependent from "./CheckInFailureDependent";
import CheckInSuccess from "./CheckInSuccess";
import CheckInWith from "./CheckInWith";
import CheckOutEvents from "./CheckOutEvents";
import CheckOutSuccess from "./CheckOutSuccess";
import { CheckOutWith } from "./CheckOutWith";
import DeviceLocation from "./DeviceLocation";
import DisplayWaiverForm from "./DisplayWaiverForm";
import { GuestCheckIn } from "./GuestCheckIn";
import GuestCheckInSuccess from "./GuestCheckInSuccess";
import { Layout } from "antd";
import { LinkExpired } from "./LinkExpired";
import ManualLocationForm from "./ManualLocationForm";
import { NothingToCheckOut } from "./NothingToCheckOut";
import React from "react";
import { Route } from "react-router-dom";
import { SelectActivity } from "./SelectActivity";
import { Timer } from "./Layout/Timer";
import ToSScanCheckin from "./ToSScanCheckin";
import ValidateMemberLogin from "./ValidateMemberLogin";
import ViewMerits from "./ViewMerits";
import ViewQualifications from "./ViewQualifications";
import WaiverFormSign from "./WaiverFormSign";
import { WebCheckinHome } from "./WebCheckinHome";

interface Props {
  readonly orgDetails: {
    readonly logoUrl: string;
    readonly name: string;
  };
  readonly notify: (message: string, status: string) => void;
}

export const WebCheckinRoute: React.FC<Props> = ({ orgDetails, notify }) => {
  return (
    <div className="webcheckin" style={{ backgroundColor: "#f0f2f5" }}>
      <Route
        exact
        path="/web-checkin/home"
        component={(): any => [
          <Layout key="web-checkin/home">
            <WebCheckinHome orgDetails={orgDetails} />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/auth0-redirect-callback"
        component={(): any => [
          <Layout>
            <Auth0RedirectCallback />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/linkexpired"
        component={(): any => [
          <Layout key="web-checkin/link-expired" style={{ background: "none" }}>
            <LinkExpired />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkinwith"
        component={(): any => [
          <Layout key="web-checkin/checkin-with">
            <CheckInWith />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkoutwith"
        component={(): any => [
          <Layout key="web-checkin/checkout-with">
            <CheckOutWith />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/aboutmerit"
        component={(): any => [
          <Layout key="web-checkin/about-merit">
            <AboutMerit />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/selectactivity"
        component={(): any => [
          <Layout key="web-checkin/select-activity" style={{ background: "none" }}>
            <SelectActivity />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/validate-member-login"
        component={(): any => [
          <Layout key="web-checkin/validate-member-login">
            <ValidateMemberLogin />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkin-success"
        component={(): any => [
          <Layout key="web-checkin/checkin-success">
            <CheckInSuccess />
            <Timer />
          </Layout>,
        ]}
      />

      <Route
        exact
        path="/web-checkin/checkin-failure"
        component={(): any => [
          <Layout key="web-checkin/checkin-failure">
            <CheckInFailure />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkin-failure-dependent"
        component={(): any => [
          <Layout key="web-checkin/checkin-failure-dependent">
            <CheckInFailureDependent />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/guestcheckin"
        component={(): any => [
          <Layout key="web-checkin/guest-checkin">
            <GuestCheckIn />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/devicelocation"
        component={(): any => [
          <Layout key="web-checkin/device-location">
            <DeviceLocation notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/guestcheckinsuccess"
        component={(): any => [
          <Layout key="web-checkin/guest-checkin-success">
            <GuestCheckInSuccess />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkoutsuccess"
        component={(): any => [
          <Layout key="web-checkin/checkout-success">
            <CheckOutSuccess />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/nothingtocheckout"
        component={(): any => [
          <Layout key="web-checkin/nothing-to-checkout">
            <NothingToCheckOut />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/waiver-form"
        component={(): any => [
          <Layout key="web-checkin/waiver-form">
            <DisplayWaiverForm notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/waiver-form-sign"
        component={(): any => [
          <Layout key="web-checkin/waiver-form-sign" style={{ background: "none" }}>
            <WaiverFormSign notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/view-qualifications"
        component={(): any => [
          <Layout key="web-checkin/view-qualifications" style={{ background: "none" }}>
            <ViewQualifications />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/view-merits"
        component={(): any => [
          <Layout key="web-checkin/view-merits" style={{ background: "none" }}>
            <ViewMerits />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/qr-scan"
        component={(): any => [
          <Layout key="web-checkin/qr-scan">
            <CheckInCheckOutQrScan notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkout-events"
        component={(): any => [
          <Layout key="web-checkin-/checkout-events">
            <CheckOutEvents notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkin-access"
        component={(): any => [
          <Layout key="web-checkin/checkin-access" style={{ background: "none" }}>
            <CheckInAccessMerit orgDetails={orgDetails} notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/already-checkedin"
        component={(): any => [
          <Layout key="web-checkin/already-checkin">
            <AlreadyCheckIn />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/input-location"
        component={(): any => [
          <Layout key="web-checkin/input-location">
            <ManualLocationForm notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/qr-scan/tos"
        component={(): any => [
          <Layout key="web-checkin/qr-scan/tos">
            <ToSScanCheckin />
            <Timer />
          </Layout>,
        ]}
      />
    </div>
  );
};
