import { Button, Checkbox, Grid } from "@material-ui/core";
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router";
import { StyleSheet, css } from "aphrodite";
import { useNoPasswordCheckin, usePersonalQRScanCheckin } from "../../hooks/useCheckin";
import { AgentClient } from "../../services/AgentClient";
import CloseIcon from "@material-ui/icons/Close";
import { Fonts } from "../../theme/Fonts";
import MeritIcon from "../../assets/Images/Icons/merit_icon.svg";
// eslint-disable-next-line no-unused-vars
import { MeritUserInfo } from "../../types/user";
import { Typography } from "@material-ui/core";
import { UnreachableCaseError } from "../../utils/UnreachableCaseError";
import UserMeritRelationLogo from "../../assets/Images/user-merit-relation.png";
import history from "../../history";
import jwt_decode from "jwt-decode";
import { showErrorToast } from "../../utils/ToastHelper";

const styles = StyleSheet.create({
  acceptToS: {
    paddingBottom: 10,
    paddingTop: 20,
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: 4,
    fontFamily: Fonts.sfCompactDisplayRegular,
    padding: "30px",
    paddingHorizontal: 32,
    paddingVertical: 32,
  },
  contents: {
    paddingTop: 40,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 30,
    paddingTop: 10,
  },
  headerContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 44,
  },
  meritLogo: {
    margin: "auto",
    width: 80,
  },
  textBold: {
    // hack to have bold text, since the default font family (Roboto) doesn't have a bold variant
    // and SF Compact Display Regular doesn't look good as default font
    fontFamily: Fonts.sfCompactDisplay,
  },
  viewMeritListItem: {
    borderBottom: "1px solid #E2E6E8",
    borderTop: "1px solid #E2E6E8",
    padding: "10px 0",
  },
  viewNameEmailListItem: {
    borderBottom: "1px solid #E2E6E8",
    borderTop: "1px solid #E2E6E8",
    marginTop: 20,
    padding: "10px 0",
  },
  wrapper: {
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    flex: 1,
    height: "100vh",
    justifyContent: "center",
  },
});

interface LocationState {
  readonly accessToken: string;
  readonly checkinType: "PERSONAL_QR_CODE_SCAN" | "NO_PASSWORD_QR_CODE_SCAN";
  readonly qrData: string;
}
interface Props extends RouteComponentProps<{}, {}, LocationState> {}

const ToSScanCheckin = ({
  location: {
    state: { accessToken, checkinType, qrData },
  },
}: Props) => {
  const handlePersonalQRCodeScan = usePersonalQRScanCheckin();
  const handleNoPasswordQRCodeScan = useNoPasswordCheckin();

  const { event } = JSON.parse(localStorage.getItem("eventData")!);
  const { dependentEventId, eventId }: { dependentEventId: number; eventId: number } = event;

  const [isToSAccepted, setIsToSAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const orgName = localStorage.getItem("name");

  const handleToSCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsToSAccepted(event.target.checked);
  };

  const acceptToSAndLinkAgent = async (accessToken: string) => {
    const agentClient = AgentClient(accessToken);
    const { agentID, entityID } = jwt_decode<MeritUserInfo>(accessToken);

    await agentClient.acceptToS(agentID);
    await agentClient.linkEntity(agentID, entityID);
  };

  const onAccept = async () => {
    try {
      setIsSubmitting(true);
      await acceptToSAndLinkAgent(accessToken);

      switch (checkinType) {
        case "PERSONAL_QR_CODE_SCAN":
          await handlePersonalQRCodeScan(eventId, qrData);

          return;
        case "NO_PASSWORD_QR_CODE_SCAN":
          await handleNoPasswordQRCodeScan(eventId, dependentEventId, qrData);

          return;
        default:
          throw new UnreachableCaseError(checkinType);
      }
    } catch (error) {
      showErrorToast({ message: "There was a problem while accepting terms of service" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onCancel = () => {
    const { replace } = history;
    replace("/web-checkin/home");
  };

  return (
    <Grid container className={css(styles.wrapper)}>
      <Grid item xs={11} sm={8} md={4}>
        <Grid container className={css(styles.container)}>
          <Grid item xs={12}>
            <Grid container className={css(styles.headerContainer)}>
              <img alt="Merit logo" className={css(styles.meritLogo)} src={MeritIcon} />
              <CloseIcon cursor="pointer" onClick={onCancel} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <img alt="User Merit relation logo" src={UserMeritRelationLogo} style={{ height: 76, width: 230 }} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={css(styles.contents)}>
              <Grid item xs={12}>
                <Typography variant="h5" data-testid="header-TermsOfServiceModal">
                  Merit Check-in
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  In order to check in to this activity, do you allow <strong>{orgName}</strong> to take the actions?
                </Typography>
              </Grid>

              <Grid item xs={12} className={css(styles.viewNameEmailListItem)}>
                <Typography variant="subtitle1" className={css(styles.textBold)}>
                  <span style={{ marginRight: 10 }}>*</span>
                  <span data-testid="viewMeritsHeader-TermsOfServiceModal">View your merits and field data</span>
                </Typography>
                <Typography variant="subtitle1" className={css(styles.textBold)}>
                  <span style={{ marginRight: 10 }}>*</span>
                  <span data-testid="viewMeritsHeader-TermsOfServiceModal">Validate a Policy against your merits</span>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" className={css(styles.acceptToS)}>
                  <Checkbox
                    color="primary"
                    checked={isToSAccepted}
                    onChange={handleToSCheckboxChange}
                    style={{ padding: "0 5px 0 0" }}
                    data-testid="tosCheckbox-TermsOfServiceModal"
                  />
                  <Typography variant="body2">
                    Accept{" "}
                    <a
                      target="_blank"
                      href="https://app.merits.com/terms-of-service"
                      style={{ textDecorationLine: "underline" }}
                    >
                      Terms of Service
                    </a>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" className={css(styles.footer)} spacing={16}>
                  <Grid item>
                    <Button
                      aria-label="Add"
                      variant="outlined"
                      color="default"
                      onClick={onCancel}
                      disabled={isSubmitting}
                      data-testid="cancelButton-TermsOfServiceModal"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      aria-label="Add"
                      variant="contained"
                      color="primary"
                      disabled={!isToSAccepted || isSubmitting}
                      onClick={onAccept}
                      data-testid="allowButton-TermsOfServiceModal"
                    >
                      Allow
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  To learn how Merit Check-in will handle your data, please review{" "}
                  <a
                    target="_blank"
                    href="https://app.merits.com/terms-of-service"
                    style={{ textDecorationLine: "underline" }}
                  >
                    terms of service
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://app.merits.com/privacy-policy"
                    style={{ textDecorationLine: "underline" }}
                  >
                    privacy policy
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(ToSScanCheckin);
