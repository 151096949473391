/* eslint-disable no-irregular-whitespace*/
import { getAuthHeader, getCheckinAuthHeader } from "../../../constant/api";
import Cookies from "js-cookie";
import { None } from "../../../utils/None";
import { Some } from "../../../utils/Some";
import axios from "../../../utils/ApiInterceptor";

interface response<T> {
  data: {
    item: T[];
    success: boolean;
    status: string;
    groups?: T[];
  };
}

// TODO: Do we get any valid value for this?
const getCookie = () => Cookies.get("auth");
const getCookieHeader = () => {
  return { Authorization: `Bearer ${getCookie()}` };
};

const getOrgId = () => JSON.parse(Cookies.get("virtualKioskIdentification") ?? "{}").sigmaOrgId;

export async function getEnvironment() {
  return await axios.get<EnvironmentResponse>("/api/environment");
}

export async function getVirtualKioskActivities(): Promise<response<EventObject>> {
  const orgId = getOrgId();
  const url = `/api/kiosk-event/${orgId}`;
  const URL: Promise<response<EventObject>> = axios.get(url, {
    headers: getCookieHeader(),
  });
  const response = await URL;

  return response;
}

export async function addCheckin(query: string, params: any): Promise<response<CheckinResponse>> {
  const URL: Promise<response<CheckinResponse>> = axios.post(`/api/event-attendee/member-success${query}`, params, {
    headers: await getCheckinAuthHeader(),
  });
  const response = await URL;

  return response;
}

export async function retrieveMerits(query: string, params: any): Promise<response<ValidateMeritResponse>> {
  const orgId = getOrgId();

  if (None(orgId)) {
    throw new Error("No orgId found");
  }

  const URL: Promise<response<RetrieveMeritResponse>> = axios.post(`/api/org/${orgId}/retrieveMerits${query}`, params, {
    headers: await getCheckinAuthHeader(),
  });
  const response = await URL;

  return response;
}

export async function validateMerits(
  query: string,
  params: any,
  useCookie?: boolean,
): Promise<response<ValidateMeritResponse>> {
  const orgId = getOrgId();

  if (None(orgId)) {
    throw new Error("No orgId found");
  }

  const URL: Promise<response<ValidateMeritResponse>> = axios.post(`/api/org/${orgId}/validateMerits${query}`, params, {
    headers: Some(useCookie) && useCookie ? getCookieHeader() : await getCheckinAuthHeader(),
  });
  const response = await URL;

  return response;
}

export async function guestCheckin(params: {}): Promise<response<CheckinResponse>> {
  const URL: Promise<response<CheckinResponse>> = axios.post(`/api/event-attendee/new-member`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getWaiver(waiverId: number): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.get(`/api/waiver/get-waiver?waiverId=${waiverId}`, {
    headers: getCookieHeader(),
  });
  const response = await URL;

  return response;
}

/**
 * @deprecated This API has been removed as part of the code cleanup for the Sigma client [RN-1077].
 */
export async function getWavierSignIn(params: {
  readonly eventAttendeeId: number;
  readonly fieldIds: Array<{ readonly id: string; readonly value: string }>;
  readonly signImg: string;
  readonly waiverId: number;
}): Promise<response<{ readonly status: string }>> {
  const URL: Promise<response<{ readonly status: string }>> = axios.post("/api/waiver/sign-waiver", params, {
    headers: getCookieHeader(),
  });
  const response = await URL;

  return response;
}

/**
 * @deprecated This API has been removed as part of the code cleanup for the Sigma client [RN-1077].
 */
export async function scanCheckInQr(params: {
  readonly data: string;
  readonly eventId: number;
  readonly allowDuplicates: boolean;
}): Promise<response<CheckinResponse>> {
  const URL: Promise<response<CheckinResponse>> = axios.post("/api/event-attendee/member-login-with-scan", params, {
    headers: getCookieHeader(),
  });
  const response = await URL;

  return response;
}

export async function getQualification(qualificationId: number): Promise<response<any>> {
  const URL: Promise<response<any>> = axios.get(
    `/api/qualification/get-qualification?qualificationId=${qualificationId}`,
    {
      headers: getCookieHeader(),
    },
  );
  const response = await URL;

  return response;
}

export async function getCheckOutEvents(data: string) {
  const response = await axios.get<CheckOutSuccessResponse>(
    `/api/event-attendee/checkout-success?memberIdToken=${data}&isKiosk=1`,
    {
      headers: await getCheckinAuthHeader(),
    },
  );

  return response;
}

export async function checkOut(
  params: { eventAttendeeId: number[] },
  useCookie?: boolean,
): Promise<response<Array<CheckOutResponse>>> {
  const URL: Promise<response<Array<CheckOutResponse>>> = axios.post("/api/event-attendee/checkout", params, {
    headers: Some(useCookie) && useCookie ? getCookieHeader() : await getCheckinAuthHeader(),
  });
  const response = await URL;

  return response;
}

/**
 * @deprecated This API has been removed as part of the code cleanup for the Sigma client [RN-1077].
 */
export async function getCheckInAccessMerit(params: {
  readonly eventId: number;
  readonly memberId: number;
  readonly allowDuplicates: boolean;
}): Promise<response<AccessMerit>> {
  const URL: Promise<response<AccessMerit>> = axios.post("/api/event-attendee/member-check-access-merit", params, {
    headers: getCookieHeader(),
  });
  const response = await URL;

  return response;
}

export async function getReverseGeocodingInfo(latitude: number, longitude: number) {
  const MAPS_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=${process.env.MAPS_API_KEY}`;
  const response = await axios.get(MAPS_URL);

  return response;
}

export async function getEmail(translationUUID: string) {
  const orgId = getOrgId();
  const response = await axios.get<GetEmailFromTranslationUUIDResponse>(
    `/api/event-attendee/${orgId}/uuid-translation?translationUUID=${translationUUID}`,
    {
      headers: getCookieHeader(),
    },
  );

  return response;
}

export async function checkInWithPersonalQRCode(qrData: string, eventId: number, location?: LocationDetails) {
  const orgId = getOrgId();
  const response = await axios.post<CheckInWithPersonalQRCodeResponse>(
    `/api/event-attendee/${orgId}/member-checkin-with-dynamic-scan`,
    { data: qrData, eventId, location },
    {
      headers: getCookieHeader(),
    },
  );

  return response;
}

export async function checkOutWithPersonalQRCode(qrData: string) {
  const orgId = getOrgId();

  const response = await axios.get<CheckOutWithPersonalQRCodeResponse>(
    `/api/event-attendee/${orgId}/member-checkout-success?qRCodeToken=${qrData}&isKiosk=1`,
    {
      headers: getCookieHeader(),
    },
  );

  return response;
}

export async function checkInWithNoPasswordStaticQRCode(params: CheckInWithNoPasswordStaticQRCodeRequest) {
  const orgId = getOrgId();
  const response = await axios.post<CheckInWithNoPasswordStaticQRCodeResponse>(
    `/api/event-attendee/${orgId}/member-checkin-with-no-password-scan`,
    params,
    {
      headers: getCookieHeader(),
    },
  );

  return response;
}
