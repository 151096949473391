import { Layout, Row } from "antd";
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { useNoPasswordCheckin, usePersonalQRScanCheckin, useStaticQRScanCheckin } from "../../hooks/useCheckin";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import LoadingScreen from "../UI-Components/LoadingScreen";
import QrReader from "react-qr-reader";
import { Some } from "../../utils/Some";
import history from "../../history";
import { isValidPersonalQRCode } from "../../utils/PersonalQRCodeHelper";
import { isValidUUID } from "../../utils/IsValidUUID";
import scanIcon from "../../assets/Images/Icons/scanIcon.svg";
import { showErrorToast } from "../../utils/ToastHelper";

const styles = StyleSheet.create({
  activityName: {
    color: Colors.sherpa,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "30px",
  },
  contentWrapper: {
    fontFamily: Fonts.roboto,
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingTop: "24px",
  },
  qrScanText: {
    color: Colors.black,
    fontFamily: Fonts.sfCompactDisplayThin,
    fontSize: "14px",
    lineHeight: "140%",
    marginTop: "18px",
    opacity: 0.8,
    width: "327px",
  },
  scanContainer: {
    alignItems: "center",
    background: "black",
    display: "flex",
    height: "280px",
    justifyContent: "center",
    width: "375px",
  },
  scanText: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
  },
});

interface LocationState {
  checkIn?: boolean;
  checkOut?: boolean;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {
  readonly notify: (message: string | React.ReactNode, status: string) => void;
}

const showFailedCheckedInToast = () => {
  const logInWith = localStorage.getItem("logInWith")?.toLowerCase() ?? "checkin";

  showErrorToast({
    message: `Failed to ${logInWith} with the provided QR code`,
  });
};

const CheckInCheckOutQrScan: React.FC<Props> = ({ notify, location }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleStaticQRCodeScan = useStaticQRScanCheckin();
  const handlePersonalQRCodeScan = usePersonalQRScanCheckin();
  const handleNoPasswordQRCodeScan = useNoPasswordCheckin();

  const { Content } = Layout;
  const { push, goBack } = history;

  const { event } = JSON.parse(localStorage.getItem("eventData")!);
  const {
    dependentEventId,
    eventId,
    noPasswordCheckin,
  }: { dependentEventId: number; eventId: number; noPasswordCheckin: boolean } = event;

  const handleError = () => {
    notify("Camera access required", "error");
  };

  const handleScan = async (qrData: string | null) => {
    if (Some(qrData)) {
      setLoading(true);

      try {
        if (isValidUUID(qrData)) {
          if (noPasswordCheckin && dependentEventId) {
            await handleNoPasswordQRCodeScan(eventId, dependentEventId, qrData);

            return;
          }

          await handleStaticQRCodeScan(qrData);

          return;
        }

        // If it is not a static qr code, then it could be a personal qr code
        if (isValidPersonalQRCode(qrData)) {
          await handlePersonalQRCodeScan(eventId, qrData);

          return;
        }

        // We do not support any other scan apart from static and personal qr codes
        throw new Error("Invalid QR code");
      } catch (error) {
        showFailedCheckedInToast();
      }

      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <>
          <Header exitButton backArrow handleExit={() => push("/web-checkin/home")} handleGoBack={() => goBack()} />
          <Content className={css(styles.contentWrapper)}>
            <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
              <div style={{ width: "327px" }}>
                <p className={css(styles.activityName)}>{location.state.checkIn && event.name}</p>
                <p className={css(styles.scanText)}>Scan QR code</p>
              </div>
              <div className={css(styles.scanContainer)}>
                <QrReader
                  delay={1000}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ height: "280px", width: "100%" }}
                  className="qr_scanner"
                />
              </div>
              <div className={css(styles.iconContainer)}>
                <img src={scanIcon} />
              </div>
              <div className={css(styles.qrScanText)}>
                Hold your QR code such that it appears with in the box above to scan it
              </div>
            </Row>
          </Content>
          <Footer />
        </>
      )}
    </>
  );
};

export default withRouter(CheckInCheckOutQrScan);
