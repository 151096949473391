import { None } from "./None";
import jwtDecode from "jwt-decode";

type PersonalQRCodeType = {
  readonly exp: number;
  readonly iat: number;
  readonly data: {
    readonly entityID: string;
  };
};

const decodePersonalQRCode = (qrCode: string) => {
  try {
    const decodedPersonalQRCode = jwtDecode<PersonalQRCodeType>(qrCode);

    return decodedPersonalQRCode;
  } catch (error) {
    return null;
  }
};

export const isValidPersonalQRCode = (qrCode: string) => {
  const decodedData = decodePersonalQRCode(qrCode);

  if (None(decodedData) || decodedData.exp < Date.now() / 1000) {
    return false;
  }

  return true;
};
