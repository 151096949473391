import React, { useEffect, useState } from "react";
import { AgentClient } from "../../services/AgentClient";
import LoadingScreen from "../UI-Components/LoadingScreen";
// eslint-disable-next-line no-unused-vars
import { MeritUserInfo } from "../../types/user";
import { Some } from "../../utils/Some";
import { TermsOfServiceModal } from "../TermsOfServiceModal";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import history from "../../history";
import jwt_decode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "../../store/store";
import { withRouter } from "react-router-dom";

const Auth0RedirectCallback = () => {
  const { intermediateScreen, setIntermediateScreen } = useStore();
  const adminEntityId = useStore((state) => state.adminEntityId);

  const [hasFetchedLinks, setHasFetchedLinks] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getLinks = async () => {
      const accessToken = await getAccessTokenSilently();
      const { agentID, entityID } = jwt_decode<MeritUserInfo>(accessToken);

      const agentClient = AgentClient(accessToken);
      const { capabilitiesApproved, tosAccepted } = await agentClient.getLinks(agentID);

      if (capabilitiesApproved && tosAccepted) {
        setIntermediateScreen("selectOrg");
      } else {
        setIntermediateScreen("termsOfService");
      }

      setHasFetchedLinks(true);

      datadogRum.setUser({ checkinUserId: entityID, ...(Some(adminEntityId) && { adminEntityId: entityID }) });
      datadogLogs.setUser({ checkinUserId: entityID, ...(Some(adminEntityId) && { adminEntityId: entityID }) });
    };

    getLinks();
  }, []);

  if (hasFetchedLinks && intermediateScreen === "termsOfService") {
    return <TermsOfServiceModal />;
  }

  if (hasFetchedLinks && intermediateScreen === "selectOrg") {
    setIntermediateScreen(undefined);
    history.push("/web-checkin/validate-member-login");

    return null;
  }

  return <LoadingScreen minHeight="calc(100vh - 125px)" />;
};

export default withRouter(Auth0RedirectCallback);
