// Copyright 2024 Merit International Inc. All Rights Reserved

export type LDFeatureFlags = {
  readonly rolloutAdminPortalAuth0OrgAuth: boolean;
  readonly showWebQrScan: boolean;
};

export const featureFlagDefaults: LDFeatureFlags = {
  rolloutAdminPortalAuth0OrgAuth: false,
  showWebQrScan: false,
};
